<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="长租订单信息">
        <icon-button slot="extra" icon="return" @click.native="goBack"/>
        <el-descriptions :column="2" border class="margin-top">
          <el-descriptions-item>
            <template slot="label"> 长租订单编号</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 结算方名称</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 车场名称</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 套餐名称</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 主车牌</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 副车牌</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 生效时间</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 到期时间</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 有效时段</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 添加途径</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 购买数量</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单金额</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 付款状态</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单状态</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 执行状态</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 联系人</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 联系电话</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 所属单位</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 泊位号</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 备注</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 创建人</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 创建时间</template>
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
    </div>

    <div class="content-box section" style="margin-top: 18px">
      <easy-card title="停车记录">
        <el-form
            ref="query"
            :inline="true"
            :model="query"
            class="demo-form-inline"
            size="medium"
        >
          <el-form-item prop="orderCode">
            <el-input
                v-model="query.orderCode"
                clearable
                placeholder="请输入订单编号"
                prefix-icon=""
                style="width: 260px"
            ></el-input>
          </el-form-item>

          <el-form-item prop="VehicleId">
            <NumplateSuggest v-model="query.VehicleId" style="width: 260px"/>
          </el-form-item>

          <el-form-item prop="parkingOrderType">
            <el-select
                v-model="query.parkingOrderType"
                clearable
                placeholder="请选择-订单类型"
                style="width: 260px"
            >
              <el-option label="临停" value="0"></el-option>
              <el-option label="长租" value="1"></el-option>
              <el-option label="白名单" value="2"></el-option>
              <el-option label="黑名单" value="3"></el-option>
              <!-- <el-option label="预存" value="4"></el-option>
            <el-option label="访客" value="5"></el-option>
            <el-option label="共享" value="6"></el-option> -->
              <!-- <el-option label="预约" value="7"></el-option>
            <el-option label="分组" value="8"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="doSearch">搜索</el-button>
            <el-button @click="resetQueryForm">重置</el-button>
          </el-form-item>
        </el-form>
        <div class="op-box">
          <div class="op-content">
            共<span class="total-size">{{ total }}</span
          >条信息
          </div>
        </div>

        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column
              align="center"
              label="订单编号"
              prop="parklotName"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="车场名称"
              prop="parklotName"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="车牌号"
              prop="parklotName"
              width="200"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="订单类型"
              prop="parklotName"
              width="200"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="驶入时间"
              prop="parklotName"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="驶出时间"
              prop="parklotName"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="停车时长"
              prop="parklotName"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="停车图片"
              prop="parklotName"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="订单状态"
              prop="parklotName"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="商户发券应付金额"
              prop="parklotName"
              width="240"
          >
          </el-table-column>
        </el-table>

        <div class="pagination-box">
          <el-pagination
              :current-page="pagination.pageNo"
              :page-size="pagination.pageSize"
              :total="pagination.totalSize"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </easy-card>
    </div>

    <div class="content-box section" style="margin-top: 18px">
      <easy-card title="订单日志">
        <div class="op-box">
          <div class="op-content">
            共<span class="total-size">{{ total }}</span
          >条信息
          </div>
        </div>

        <el-table
            v-loading="isLoading"
            :data="recordDataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column
              align="center"
              label="操作时间"
              prop="parklotName"
              width="300"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作类型"
              prop="parklotName"
              width="300"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作详情"
              prop="parklotName"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作人类型"
              prop="parklotName"
              width="300"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作人"
              prop="parklotName"
              width="300"
          >
          </el-table-column>
        </el-table>

        <div class="pagination-box">
          <el-pagination
              :current-page="pagination.pageNo"
              :page-size="pagination.pageSize"
              :total="pagination.totalSize"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </easy-card>
    </div>
  </div>
</template>

<script>
import NumplateSuggest from "@/components/NumplateSuggest";
import BaseMixin from "@/mixins/base";

export default {
  name: "lease_order_detail",
  mixins: [BaseMixin],
  components: {
    NumplateSuggest,
  },
  data() {
    return {
      query: {
        orderCode: "",
        VehicleId: "",
        parkingOrderType: "",
      },
      dataList: [],
      recordDataList: [],
    };
  },
  methods: {
    //返回长租套餐页面
    goBack() {
      this.$router.back();
    },
    //查询
    doSearch() {
    },
    //重置
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.doSearch();
    },
  },
  mounted() {
    this.id = this.$route.query.id;
  },
};
</script>

<style lang="less" scoped>
</style>
